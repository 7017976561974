import { Deserializer } from "jsonapi-serializer"

import camelize from "./camelize"

export default function deserialize(serialData) {
  return new Promise((resolve, reject) => {
    new Deserializer().deserialize(serialData, function(err, data) {
      err ? reject(err) : resolve(camelize(data))
    })
  })
}
