import axios from "axios"
import get from "lodash/get"

import authStore from "../stores/auth"

const getHeaders = ({ token }) => {
  const authToken = token || get(authStore, "state.token")
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  }

  if (authToken) {
    headers.Authorization = authToken
  }

  return headers
}

const apiCaller = method => (url, data, opts = {}) =>
  axios({
    method,
    baseURL: process.env.RP_BASE_URL,
    headers: getHeaders(opts),
    url,
    data,
    ...opts,
  })

const api = {
  get: apiCaller("get"),
  post: apiCaller("post"),
  put: apiCaller("put"),
  delete: apiCaller("delete"),
  patch: apiCaller("patch"),
}

export default api
