import React from "react"
import { Helmet } from "react-helmet"
import styled, { createGlobalStyle } from "styled-components"

import { Subscribe, Provider } from "unstated"

import logrocket from "./src/utils/logrocket"
import authStore, { AuthContainer } from "./src/stores/auth"

const GlobalStyle = createGlobalStyle`
  html, input, button, select {
    font-family: "Lato", sans-serif;
  }

  body {
    margin: 0;
    overflow-y: scroll;
  }

  * {
    box-sizing: border-box;
  }

  table {
    border-spacing: 0;
    border-collapse: collapse;
  }

  .grecaptcha-badge { visibility: hidden; }
`

class SessionCheck extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      hidden: false,
    }
  }

  handleCheckSession = () => {
    this.setState({ loading: false })
  }

  silentAuth = () => {
    this.props.authStore.silentAuth(this.handleCheckSession)
  }

  handleVisibilityChange = () => {
    const hidden = window.document.hidden
    // Page came to focus
    if (!hidden && this.state.hidden) {
      this.silentAuth()
    }

    this.setState({ hidden })
  }

  componentDidMount() {
    logrocket.init()
    this.silentAuth()
    window.document.addEventListener(
      "visibilitychange",
      this.handleVisibilityChange,
      false
    )
  }

  componentWillUnmount() {
    window.document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange,
      false
    )
  }

  render() {
    return this.props.children
  }
}

const ModalRoot = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 300;
  top: 0;
  position: fixed;
`

export default ({ element }) => {
  return (
    <Provider inject={[authStore]}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:url" content="https://mountproxies.com/" />
        <meta
          property="og:title"
          content="MountProxies  | The best dedicated premium 5G LTE proxies"
        />
        <meta
          property="og:description"
          content="The best dedicated premium 5G LTE proxies."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dmryieb7p/image/upload/v1715230676/MP%20Website/OG_Tag_Website_f1rw8i.jpg"
        />

        <title>MountProxies | The best dedicated premium 5G LTE proxies</title>
        <link rel="preconnect" href="https://m.stripe.com" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        {process.env.NODE_ENV === "production" && (
          <>
            <script>{`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '931051891704420');
          fbq('track', 'PageView');
        `}</script>
            <noscript>{`
          <img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=931051891704420&ev=PageView&noscript=1"
          />
        `}</noscript>
          </>
        )}
      </Helmet>
      <GlobalStyle />
      <Subscribe to={[AuthContainer]}>
        {auth => (
          <SessionCheck authStore={auth}>
            <ModalRoot id="modal-root" />
            {React.cloneElement(element, { authStore: auth })}
          </SessionCheck>
        )}
      </Subscribe>
    </Provider>
  )
}
