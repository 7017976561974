import { Container } from "unstated"

import { navigate } from "gatsby"
import { get, noop } from "lodash"
import { parse } from "query-string"

import { getProfile } from "../utils/CallAPIs"
import logrocket from "../utils/logrocket"
import debounce from "../utils/debounce"
import api from "../utils/api"
import { isCurrentRouteProtected } from "../utils/routes"

const isBrowser = typeof window !== "undefined"

const genericError = "Oops! something bad happened. Please try again later."

export class AuthContainer extends Container {
  constructor(props) {
    super(props)

    this.state = {
      user: null,
      token: null,
      error: null,
    }

    this.silentAuth = debounce(this.silentAuth)
  }

  get isAuthenticated() {
    if (!isBrowser) {
      return false
    }

    return !!this.state.token
  }

  clearErrors = callback => {
    this.setState({ error: null }, callback)
  }

  login = async () => {
    let targetUrl = window.location.pathname
    const blackListed = ["/", "/login-error", "/createaccount"]

    if (blackListed.includes(targetUrl)) {
      targetUrl = localStorage.targetUrl ? localStorage.targetUrl : "/proxies"
    } else {
      targetUrl += window.location.search
    }

    localStorage.removeItem("user")
    localStorage.setItem("targetUrl", targetUrl)
    navigate(`/createaccount?form=select_method`)
  }

  loginUser = async ({ email, password, greToken, v2 }) => {
    try {
      this.setState({ error: null })
      const res = await api.post("/auth/signin", {
        email,
        password,
        gre_token: greToken,
        v2,
      })
      const { token } = res.data
      return this.getProfile(token, this.handleRedirect)
    } catch (e) {
      const status = get(e, "response.status")

      if (status === 400) {
        return { status: 400 }
      }

      const error = get(e, "response.data.msg", genericError)
      await this.setState({ error })
      console.error(e)
    }
  }

  signup = async ({ email, password, greToken, v2 }) => {
    try {
      this.setState({ error: null })
      const res = await api.post("/auth/signup", {
        email,
        password,
        gre_token: greToken,
        v2,
      })
      const {
        data: { data },
      } = res
      return data
    } catch (e) {
      const status = get(e, "response.status")

      if (status === 400) {
        return { status: 400 }
      }

      const error = get(e, "response.data.msg", genericError)
      await this.setState({ error })
      console.error(e)
    }
  }

  acccountCreateSurvey = async ({
    email,
    sourceFromWhereUserHeardUs,
    serviceProvider,
    locationToOffer,
  }) => {
    try {
      this.setState({ error: null })
      const res = await api.post("/auth/account_creation_survey", {
        email,
        source_from_where_user_heard_us: sourceFromWhereUserHeardUs,
        service_provider: serviceProvider,
        location_to_offer: locationToOffer,
      })
      const { msg } = res.data
      return msg
    } catch (e) {
      const status = get(e, "response.status")

      if (status === 400) {
        return { status: 400 }
      }

      const error = get(e, "response.data.msg", genericError)
      await this.setState({ error })
      console.error(e)
    }
  }

  requestReset = async ({ email, greToken, v2 }) => {
    try {
      this.setState({ error: null })
      const res = await api.post("/auth/request_reset_password", {
        email,
        gre_token: greToken,
        v2,
      })
      const { msg } = res.data
      navigate(`/createaccount?form=reset_succeed`)
      return msg
    } catch (e) {
      const status = get(e, "response.status")

      if (status === 400) {
        return { status: 400 }
      }

      const error = get(e, "response.data.msg", genericError)
      await this.setState({ error })
      console.error(e)
    }
  }

  handleRedirect = () => {
    const targetUrl = localStorage.targetUrl || "/proxies"
    navigate(targetUrl)
    localStorage.removeItem("targetUrl")
  }

  getProfile = async (token = this.state.token, cb = noop) => {
    const user = await getProfile(token)
    await this.setState({ user, error: null, token })
    cb(user)
    // side-effects
    logrocket.identify(user)
    localStorage.setItem("user", JSON.stringify({ ...user, token }))

    return user
  }

  silentAuth = async (callback = noop) => {
    let { user, token } = this.state
    const forceLogin = isCurrentRouteProtected()

    if (!token && localStorage.user && localStorage.user !== "undefined") {
      try {
        const { token: savedTokens, ...rest } = JSON.parse(localStorage.user)
        user = rest
        token = savedTokens

        await this.setState({ user, token })
      } catch (e) {
        console.error(e)
      }
    }

    if (token) {
      let profile, error
      try {
        profile = await getProfile(token)
      } catch (e) {
        console.error(e)
        error = e
      }
      if (!profile || error) {
        localStorage.removeItem("user")
        token = null
        this.setState({ token })
      }
    }

    if (!token && forceLogin) {
      return this.login()
    }

    return callback()
  }

  confirmEmail = async () => {
    const { token } = parse(window.location.search)

    if (!token) {
      return navigate("/")
    }

    try {
      this.setState({ error: null })
      const res = await api.post("/auth/confirm_account", {
        token,
      })
      localStorage.setItem("targetUrl", "/proxies")
      return this.getProfile(res.data.token, this.handleRedirect)
    } catch (e) {
      console.error(e)
      const error = get(e, "response.data.msg", genericError)
      await this.setState({ error })
      navigate("/createaccount")
    }
  }

  resetPassword = async ({ password }) => {
    if (!isBrowser) {
      return
    }

    const { token } = parse(window.location.search)

    if (!token) {
      return navigate("/")
    }

    try {
      this.setState({ error: null })
      const res = await api.post("/auth/reset_password", {
        token,
        password,
      })
      return this.getProfile(res.data.token, this.handleRedirect)
    } catch (e) {
      const error = get(e, "response.data.msg", genericError)
      await this.setState({ error })
      console.error(e)
    }
  }

  logout = async (cb = noop) => {
    navigate("/")
    localStorage.removeItem("user")
    await this.setState({ user: null, token: null })
    cb()
  }

  updateProfile = async (attr, value) => {
    try {
      const res = await api.put("/account/update", {
        [attr]: value,
      })

      let { user, token } = this.state
      user = { ...user, ...res.data.data.attributes }
      this.setState({ user })
      localStorage.setItem("user", JSON.stringify({ token, ...user }))
    } catch (e) {
      const error = get(e, "response.data.msg", genericError)
      await this.setState({ error })
      console.error(e)
    }
  }
}

export const authStore = new AuthContainer()

export default authStore
