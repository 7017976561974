import LogRocket from "logrocket"

function init() {
  LogRocket.init("staycircles/rockproxies", {
    release: process.env.GATSBY_GIT_HASH,
    network: {
      requestSanitizer: function(request) {
        if (request.headers["Authorization"]) {
          request.headers["Authorization"] = ""
        }

        return request
      },
    },
  })
}

function identify({ id, ...rest }) {
  if (!id) return
  const { name, nickname, picture, email } = rest
  LogRocket.identify(id, { name, nickname, picture, email })
}

const caller = fn => (...args) => {
  const activeEnv =
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development"

  if (activeEnv === "production") {
    fn(...args)
  }
}

export default {
  init: caller(init),
  identify: caller(identify),
}
