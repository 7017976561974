const routes = [
  { path: "/settings", protected: true },
  { path: "/addaproxy", protected: true },
  { path: "/checkout", protected: true },
  { path: "/proxies", protected: true },
  { path: "/referral", protected: true },
  { path: "/notifications", protected: true },
]

export const isCurrentRouteProtected = (location = window.location) => {
  const pathname = location.pathname.replace(/\/+$/, "")
  const currentRoute = routes.find(({ path }) => pathname.indexOf(path) === 0)

  return currentRoute ? currentRoute.protected : false
}

export default routes
