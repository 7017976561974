// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acceptableusepolicy-js": () => import("./../../../src/pages/acceptableusepolicy.js" /* webpackChunkName: "component---src-pages-acceptableusepolicy-js" */),
  "component---src-pages-addaproxy-js": () => import("./../../../src/pages/addaproxy.js" /* webpackChunkName: "component---src-pages-addaproxy-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-checkout-index-js": () => import("./../../../src/pages/checkout/index.js" /* webpackChunkName: "component---src-pages-checkout-index-js" */),
  "component---src-pages-coinify-cancel-js": () => import("./../../../src/pages/coinify/cancel.js" /* webpackChunkName: "component---src-pages-coinify-cancel-js" */),
  "component---src-pages-confirm-js": () => import("./../../../src/pages/confirm.js" /* webpackChunkName: "component---src-pages-confirm-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-createaccount-js": () => import("./../../../src/pages/createaccount.js" /* webpackChunkName: "component---src-pages-createaccount-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-error-js": () => import("./../../../src/pages/login-error.js" /* webpackChunkName: "component---src-pages-login-error-js" */),
  "component---src-pages-notifications-index-js": () => import("./../../../src/pages/notifications/index.js" /* webpackChunkName: "component---src-pages-notifications-index-js" */),
  "component---src-pages-oldcheckout-index-js": () => import("./../../../src/pages/oldcheckout/index.js" /* webpackChunkName: "component---src-pages-oldcheckout-index-js" */),
  "component---src-pages-payment-cancel-js": () => import("./../../../src/pages/payment/cancel.js" /* webpackChunkName: "component---src-pages-payment-cancel-js" */),
  "component---src-pages-payment-success-js": () => import("./../../../src/pages/payment/success.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-proxies-index-js": () => import("./../../../src/pages/proxies/index.js" /* webpackChunkName: "component---src-pages-proxies-index-js" */),
  "component---src-pages-proxies-manage-js": () => import("./../../../src/pages/proxies/manage.js" /* webpackChunkName: "component---src-pages-proxies-manage-js" */),
  "component---src-pages-referral-js": () => import("./../../../src/pages/referral.js" /* webpackChunkName: "component---src-pages-referral-js" */),
  "component---src-pages-resellerrequest-js": () => import("./../../../src/pages/resellerrequest.js" /* webpackChunkName: "component---src-pages-resellerrequest-js" */),
  "component---src-pages-settings-account-js": () => import("./../../../src/pages/settings/account.js" /* webpackChunkName: "component---src-pages-settings-account-js" */),
  "component---src-pages-settings-billing-js": () => import("./../../../src/pages/settings/billing.js" /* webpackChunkName: "component---src-pages-settings-billing-js" */),
  "component---src-pages-settings-cryptomus-recurring-subscriptions-js": () => import("./../../../src/pages/settings/cryptomus-recurring-subscriptions.js" /* webpackChunkName: "component---src-pages-settings-cryptomus-recurring-subscriptions-js" */),
  "component---src-pages-settings-manage-subscriptions-js": () => import("./../../../src/pages/settings/manage_subscriptions.js" /* webpackChunkName: "component---src-pages-settings-manage-subscriptions-js" */),
  "component---src-pages-settings-notifications-js": () => import("./../../../src/pages/settings/notifications.js" /* webpackChunkName: "component---src-pages-settings-notifications-js" */),
  "component---src-pages-settings-rockpoints-js": () => import("./../../../src/pages/settings/rockpoints.js" /* webpackChunkName: "component---src-pages-settings-rockpoints-js" */),
  "component---src-pages-settings-transactions-js": () => import("./../../../src/pages/settings/transactions.js" /* webpackChunkName: "component---src-pages-settings-transactions-js" */),
  "component---src-pages-settings-waitlist-js": () => import("./../../../src/pages/settings/waitlist.js" /* webpackChunkName: "component---src-pages-settings-waitlist-js" */),
  "component---src-pages-stripe-cancel-js": () => import("./../../../src/pages/stripe/cancel.js" /* webpackChunkName: "component---src-pages-stripe-cancel-js" */),
  "component---src-pages-stripe-success-index-js": () => import("./../../../src/pages/stripe/_success/index.js" /* webpackChunkName: "component---src-pages-stripe-success-index-js" */),
  "component---src-pages-stripe-success-js": () => import("./../../../src/pages/stripe/success.js" /* webpackChunkName: "component---src-pages-stripe-success-js" */),
  "component---src-pages-success-index-js": () => import("./../../../src/pages/_success/index.js" /* webpackChunkName: "component---src-pages-success-index-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-termsofservice-js": () => import("./../../../src/pages/termsofservice.js" /* webpackChunkName: "component---src-pages-termsofservice-js" */),
  "component---src-pages-upgrade-payment-cancel-index-js": () => import("./../../../src/pages/upgrade_payment_cancel/index.js" /* webpackChunkName: "component---src-pages-upgrade-payment-cancel-index-js" */),
  "component---src-pages-upgrade-payment-success-index-js": () => import("./../../../src/pages/upgrade_payment_success/index.js" /* webpackChunkName: "component---src-pages-upgrade-payment-success-index-js" */)
}

